
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';

import { ResetPassword } from '@/schemas/common.schemas';
import { Getters, Actions } from '@/store/enums/ForgotPasswordEnums';
import { mapActions, mapGetters } from 'vuex';
import toasts from '@/utils/toasts';
import router from '@/router';

const initialValues = {} as unknown;

export default defineComponent({
  name: 'password-reset-form',
  components: {
    Form,
    FormField,
  },
  data: () => ({
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
    formErrors: {},
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.resetPasswordForm as typeof Form;

    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_FORGOT_PASSWORD_ACTION_ERROR,
    }),

    formSchema() {
      return ResetPassword;
    },
  },
  methods: {
    ...mapActions({
      resetPassword: Actions.RESET_PASSWORD,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
      this.formErrors = {};
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    submitResetPasswordForm(values) {
      if (this.loading) return;
      this.loading = true;

      this.formData = {
        ...values,
        ...{ token: this.$route.params.token },
      };

      this.resetPassword(this.formData)
        .then(() => {
          this.loading = false;

          this.resetForm();

          toasts.success('Password successfully modified', () => {
            router.push({ name: 'sign-in' });
          });
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
});
